<template lang='pug'>
div.form__wrapper
  img.logo(src='../assets/logo.png')
  h1 Reset Password
  el-form(v-if='!submitted', :model='form', :rules='rules', ref='forgotPasswordForm', 
          v-loading='loading', @submit.prevent.native='sendResetEmail')
    el-form-item(prop='email')
      el-input(v-model='form.email', placeholder='Email', autofocus)
    el-form-item
      el-button.submit(type='primary', @click='sendResetEmail') Send me a reset link
  div(v-else) 
    p Check your 
      span.bold {{ form.email.substring(form.email.lastIndexOf('@')) }} 
      | email address for an email from us. The email will contain a password reset link that will be valid for 1 hour. Once you've clicked on that link, you'll be able to set a new password.
    p Sometimes spam filters don't like us. If you don't see a verification email, try checking your spam folder. Otherwise, please feel free to contact customer support.

  a(:href='loginUrl') 
    el-button(type='text') Back to login
</template>

<script>
import api from '../api'
import { buildRedirectUrl } from '../utils'
import Cookies from 'js-cookie'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      loading: false,
      submitted: false,
      form: {
        email: null
      },
      rules: {
        email: [
          { required: true, message: 'Enter an email address', trigger: 'submit' },
          { type: 'email', message: 'Enter a valid email address', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    loginUrl () {
      const company = Cookies.get('company')

      if (!company) {
        return '/'
      } else {
        return buildRedirectUrl(company)
      }
    }
  },
  methods: {
    sendResetEmail () {
      this.$refs.forgotPasswordForm.validate((valid) => {
        if (valid) {
          this.loading = true
          api.post('reset', this.form).then((res) => {
            this.loading = false
            this.submitted = true
          }).catch(_ => {
            this.loading = false
            this.$message.error('Something has gone wrong. Contact proton.ai if this problem persists.')
          })
        }
      })
    }
  }
}
</script>

<style lang='scss'>
@import '../scss/common';
</style>